<template>
  <div class="homeIndex">

    <div class='top'>
          <img src='https://static.yihu365.cn/img/h5Img/assets/img/home/banner_third.jpg'/>
    </div>


    <div class="list_box">
      <van-tabs
          v-model:active="active"
          color="#00C291"
          duration="0.1"
          title-active-color = "#00C291"
          title-inactive-color="#999999"
          @change="onTabChange"
      >
        <van-tab
            v-for="(item,index) in serveType.target"
            :key="item.type"
            :title="item.desc"
            title-class="tit"
        >
          <div class="list">
            <div class="cont"
                 v-for="(item,index) in serveList[active]"
                 @click="toGoodsDetail('1',item.roleCode,item.scdSvCode,item.firSvCode,item.serviceType)"
            >
              <div class="img_box">
                <img :src="item.pic" alt="">
              </div>
              <div class="serve_text">
                <div>
                  <div class="t1">{{item.serviceName}}</div>
                  <div class="t2">{{item.shortDiscrip}}</div>
                </div>
                <div class="price_t4">
                  <div class="left_p">
                    <span class="price_1">￥</span>
                    <span class="price_2">{{item.singlePrice}}</span>
                    <span class="price_3">/次</span>
                  </div>
                  <div class="btn_buy" v-if="item.subscribeNum">
                    <van-icon name="friends" size="15" color="#d1d1d1"/><span>{{item.subscribeNum}}人已购买</span>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>




    <div style='height:80px;background:#F2F2F2;'></div>
  </div>
</template>
<script>
import {setWxShareDate} from '@/libs/utils.js'
import { onMounted, ref, computed,reactive, onBeforeMount} from "vue"
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { Dialog } from 'vant'
import homeApi from '@axios/home'
import { channel } from '@/config/channel.js';


export default {
  name: 'homeTaiping',
  components: {
  },
  setup () {
    let router = useRouter();
    const store = useStore();
    const route = useRoute();
    const serveType = reactive([]);
    const serveList = reactive([]);
    const active = ref(0);

    onMounted (()=> {
      let active1 = Number(sessionStorage.getItem('active_tp'));
       if(active1){
           active.value = active1;
        }

      onTabChange();
    })

    let isIOS= !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    if(isIOS){
      window.history.pushState({}, "title", "#");
    }



    const goToListPage = (roleCode,typeCode,firSvCode,type) => {
      router.push({
        name : 'bloodList',
        query : {
          rolecode : roleCode,
          typecode:typeCode,
          firsvcode : firSvCode,
          type:type
        }
      })
    }

    const getServicesType = () => {
      homeApi.getHotServiceTypesForTaiping().then((res) => {
        serveType.target = res.data;
      })
    }
    getServicesType();

    const onTabChange = (index) => {

      console.log(active.value);

      if (!serveList[active.value]) {//没有数据才去请求
        let TypeId = (active.value + 1).toString();
        //获取服务list
        let formData = {
          type : TypeId
        }
        homeApi.getHotServicesForTaiping(formData).then((res) => {
          console.log(res);
          serveList[active.value] = res.data;
        })
      }
    }


    const toGoodsDetail = (id,roleCode,scdSvCode,firSvCode,serviceType) => {
      sessionStorage.setItem('active_tp',active.value);
      if(!id){
        router.push({
          name : 'more',
        })
        return
      }
      if( serviceType == 1){
        router.push({
          name : 'bloodList',
          query : {
            id : id,
            rolecode : roleCode,
            scdcvcode : scdSvCode,
            firsvcode : firSvCode
          }
        })
        return;
      }

      if(serviceType == 'jiyin'){

        router.push({
          name: 'genesDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            serviceType: serviceType
          }
        })
        return;
      }

      if(serviceType == '052' || serviceType == '2' ){
        router.push({
          name: 'newestDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            serviceType: serviceType
          }
        })
        return;
      }

      if(roleCode == '019'){
        router.push({
          name: 'serviceDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode
          }
        })
      }
      if(roleCode == '099'){//肥胖基因那一类
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode,
            examCode : '001'
          }
        })
      }
      else{
        router.push({
          name: 'goodsDetail',
          query: {
            rolecode: roleCode,
            firsvcode: firSvCode,
            scdsvcode: scdSvCode
          }
        })
      }
      // router.push({
      //     name : 'goodsDetail',
      //     query : {
      //         id : id,
      //         rolecode : roleCode,
      //         scdcvcode : scdSvCode,
      //         firsvcode : firSvCode
      //     }
      // })
    }

    const toHref = (url) => {
      if(!url){
        return;
      }
      window.location.href = url
    }

    return {
      channel,
      store,
      active,
      toHref,
      toGoodsDetail,
      goToListPage,
      onTabChange,
      serveList,
      serveType,
      router
    }
  },

}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.homeIndex{
  width: 100%;
  min-height: 100vh;
  background-image:linear-gradient(to bottom,#FEFEFE 0%,#F8F8F8 100px,#F2F2F2 70%);
}
.swiper_cont{
  img{
    width: 100%;
    height:200px;
    float: left;
  }
}
::v-deep(.van-swipe__indicator){
  width: 20px;
  height: 6px;
  border-radius:4px;
}
.top{
  //padding:30px;
  //padding-top:45px;
  display: flex;
  flex-wrap: wrap;
  img{
    width:100%;
    margin-bottom: 15px;
  }
  &>div{
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom:30px;
    &>div{
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #313131;
      margin-top:10px
    }
  }
}
.swiper_cont{
  margin:30px;
  margin-top:0;
  border-radius:20px;
  overflow: hidden;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  text-align: center;
  overflow: hidden;
  // display: flex;
  // background-color: #39a9ed;
}
.bitmap{
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  padding:0px 30px 10px 30px;
  .left_img{
    position: relative;
    width: 48.5%;
    img{
      width: 100%;
    }
  }
  .right_img{
    width: 48.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top_gene{
      position: relative;
    }
    .bott_jk{
      position: relative;
    }
    img{
      width: 100%;
    }
  }
  p{
    position: absolute;
    margin:0;
    left:40px;
    top:42px;
    color:#313131;
    font-size: 30px;
    font-weight: bold;
  }
}




.list_box {
  //background: #F2F2F2;
  box-sizing: border-box;
  padding: 0 30px 0 30px;

  ::v-deep(.van-tabs--line .van-tabs__wrap){
    .van-tabs__nav{
      background: none;
    }
    .van-tabs__line{
      width: 50px;
    }
    .van-tabs__nav--line{
      padding-bottom: 20px;
    }
    .van-tab--active{
      font-size: 29px;
    }
  }
  .tit {
    font-size: 30px;
    color: red;
  }

  .list {
    min-height: 600px;
    font-size: 24px;
    .cont {
      background: #FFFFFF;
      display: flex;
      box-sizing: border-box;
      padding: 20px;
      border-radius:25px;
      margin-top: 20px;
      .img_box {
        width: 200px;
        height: 200px;
        margin-right: 24px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 15px;
        }
      }

      .serve_text {
        box-sizing: border-box;
        width:450px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .t1 {
          margin-top: 10px;
          font-size: 30px;
          font-weight: 500;
          color: #313131;
          line-height: 38px;
        }

        .t2 {
          margin: 10px 0 10px 0;
          font-size: 25px;
          font-weight: 400;
          color: #666666;
          //height:90px;
          display: -webkit-box;
          overflow: hidden;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .t3 {
          height: 60px;
          span {
            display: inline-block;
            padding: 0 10px;
            height: 40px;
            border: 1px solid #00c291;
            border-radius: 4px;
            color: #00c291;
            text-align: center;
            line-height: 40px;
            margin-right: 12px;

          }
        }

        .price_t4 {
          font-family: PingFangSC-Semibold, PingFang SC;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          .left_p{
            margin-bottom: -3px;
          }
          .price_1 {
            color: #FF6161;
            font-size: 26px;
            font-weight: 600;
          }

          .price_2 {
            color: #FF6161;
            font-size: 38px;
            font-weight: 600;
          }

          .price_3 {
            color: #FF6161;
            font-size: 26px;
          }
          .btn_buy {
            font-size: 24px;
            color: #999999;
            margin-right: 10px;
            .van-icon{
              vertical-align: middle;
            }
            span{
              margin-left: 5px;
              vertical-align: middle;
            }
          }
        }
      }

    }
  }
}

.goods_cont{
  padding:30px;
  padding-top:20px;

  .goods_title{
    font-size: 36px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #313131;
    padding-left:15px;
    margin-bottom: 20px;
  }
}
.allway{
  display: flex;
  justify-content: space-between;
  height: 250px;
  &>div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  img{
    width: 335px;
  }
}

.maternal{
  width:100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &>div{
    height: 120px;
    margin-top:20px;
    border-radius: 14px;
  }
  .m_title{
    font-size: 30px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #313131;
  }
  .m_tips{
    font-size: 24px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #919090;
  }
}

.maternal{
  .bg1{
    background: #FEF5F5;
    width: 48.8%;
    img{
      width: 100%;
      /*border-radius: 14px;*/
    }
  }
  .bg2{
    background: #F8F2FF;
    width: 100%;
    img{
      width: 100%;
      border-radius: 14px;
    }
  }
}
</style>
