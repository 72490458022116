import { render } from "./homeTaiping.vue?vue&type=template&id=98450986&scoped=true"
import script from "./homeTaiping.vue?vue&type=script&lang=js"
export * from "./homeTaiping.vue?vue&type=script&lang=js"

import "./homeTaiping.vue?vue&type=style&index=0&id=98450986&rel=stylesheet%2Fscss&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-98450986"
/* hot reload */
if (module.hot) {
  script.__hmrId = "98450986"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('98450986', script)) {
    api.reload('98450986', script)
  }
  
  module.hot.accept("./homeTaiping.vue?vue&type=template&id=98450986&scoped=true", () => {
    api.rerender('98450986', render)
  })

}

script.__file = "src/views/home/homeIndex/homeTaiping.vue"

export default script